import i18n from '@/config/i18n'
import { LANGUAGE_CODES } from '../../constants'

class OptionList {
    constructor(list) {
        list.$label = function (key) {
            if (key === null || key === undefined)
                return
            return this.find(lang => lang.key === key).label
        }
        return list
    }
}

export const languageOptions = new OptionList([
    { key: LANGUAGE_CODES.german.toUpperCase(), label: 'German' },
    { key: LANGUAGE_CODES.french.toUpperCase(), label: 'French' }
])

export const salutations = new OptionList([
    { key: 'mr', label: 'Mr.' },
    { key: 'mrs', label: 'Ms./Mrs.' }
])

export const employeeOptions = new OptionList([
    { key: '50+', label: i18n.t('More than 50 employees'), category: 'A' },
    { key: '21-50', label: i18n.t('21 to 50 employees'), category: 'B' },
    { key: '9-20', label: i18n.t('9 to 20 employees'), category: 'C' },
    { key: '1-9', label: i18n.t('1 to 9 employees'), category: 'D' }
])

export const mealOptions = new OptionList([
    { key: '500+', label: i18n.t('More than 500 meals'), category: 'A' },
    { key: '151-500', label: i18n.t('151 to 500 meals'), category: 'B' },
    { key: '50-150', label: i18n.t('50 to 150 meals'), category: 'C' },
    { key: '1-50', label: i18n.t('1 to 50 meals'), category: 'D' }
])

export const getCompanyCategory = (employeesKey, mealsKey) => {
    let category = 'E'

    const employeeOption = employeeOptions.find(option => option.key === employeesKey)
    const mealOption = mealOptions.find(option => option.key === mealsKey)

    employeeOptions.forEach(option => {
        if (option.key === employeesKey && category > employeeOption.category)
            category = employeeOption.category
    })
    mealOptions.forEach(option => {
        if (option.key === mealsKey && category > mealOption.category)
            category = mealOption.category
    })
    return category
}

export const roleOptions = new OptionList([
    { key: 'administrator', label: i18n.t('Administrator') },
    { key: 'display_modify', label: i18n.t('Display & Modify') },
    { key: 'display', label: i18n.t('Display') }
])
