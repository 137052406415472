<template>
    <section class="visitor-page">
        <div class="visitor-page__body">
            <main
                ref="main"
                :class="'visitor-page__body__main '+(verticalCenter?'visitor-page__body__main--vertical-center':'')">
                <div class="visitor-page__body__main__content">
                    <slot />
                </div>
            </main>

            <header class="visitor-page__body__header">
                <div class="visitor-page__body__header__content">
                    <router-link :to="{ name:'home' }" title="Home"
                                 class="visitor-page__body__header__logo">
                        <img
                            alt="Gastrosuisse"
                            src="~@/assets/images/visitor-logo.png"
                            srcset="~@/assets/images/visitor-logo@2x.png 2x, ~@/assets/images/visitor-logo@3x.png 3x"
                        >
                    </router-link>
                    <nav class="visitor-page__body__header__locales">
                        <template v-for="option in locales">
                            <a :key="option.key"
                               :class="[(i18n.locale === option.key ? 'underline' : '')]"
                               :title="$t(option.key)"
                               @click="i18n.locale = option.key"
                            >{{ $t(option.key) }}</a>
                        </template>
                    </nav>
                </div>
            </header>

            <footer class="visitor-page__body__footer">
                <div class="visitor-page__body__footer__content">
                    <router-link :to="{ name:'impressum' }">
                        {{ $t('Impressum') }}
                        <LinkArrow />
                    </router-link>
                    <span> - {{ $t('A service provided by ') }}</span>
                    <a href="https://www.gastrosuisse.ch">
                        GastroSuisse
                        <LinkArrow />
                    </a>
                </div>
            </footer>
        </div>
    </section>
</template>

<script>
    import { languageOptions } from '@/modules/Registration/consts'
    import i18n from '@/config/i18n'
    import LinkArrow from './icons/LinkArrow'

    export default {
        name: 'VisitorPage',
        components: { LinkArrow },
        props: { verticalCenter: { type: [ Boolean ], default: false } },
        data() {
            return { languageOptions: languageOptions, i18n: i18n }
        },
        computed: {
            locales() {
                return languageOptions.map(o => ({ key: o.key.toLowerCase(), label: o.label }))
            }
        },
        methods: {
            scrollMain(scrollTop) {
                this.$refs['main'].scrollTo({ left: 0, top: scrollTop, behavior: 'smooth' })
            }
        }
    }
</script>

<style lang="scss">

</style>
