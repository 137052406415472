<template>
    <VisitorPage>
        <section id="impressum-page">
            <div class="container">
                <router-link class="back-link mb-4" :to="{name: 'landing-page'}">
                    &lt; {{ $t('Back') }}
                </router-link>
                <h3 class="typography--h1-bold mb-2">{{ $t('Impressum') }}</h3>
                <p>
                    <a :href="tosURL" target="_blank" class="small-link">
                        {{ $t('Download Tos') }}
                        <LinkArrow />
                    </a>
                    <br>
                    <br>
                </p>
                <p>
                    {{ $t('impressum content') }}
                </p>
            </div>
        </section>
    </VisitorPage>
</template>

<script>
    import VisitorPage from '@/components/VisitorPage'
    import LinkArrow from '@/components/icons/LinkArrow'

    export default {
        name: 'Impressum',
        components: { LinkArrow, VisitorPage },
        computed: {
            tosURL() {
                return this.$i18n.locale === 'de'
                    ? '/Allgemeine-Geschaeftsbestimmungen-HC_D_2024.pdf'
                    : 'Allgemeine-Geschaeftsbestimmungen-HC_FR_2024.pdf'
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "~@/assets/vars";

    #impressum-page {

    }

    .small-link {
        font-family: Helvetica Neue;
        font-style: normal;
        font-size: 14px;
        line-height: 23px;
        letter-spacing: 0.02em;
        border-bottom: 1px solid transparent;
        position: relative;
        padding-right: 13px;
        padding-bottom: 0;
        border-bottom: 1px solid black;
        margin-right: 5px;
        font-weight: 500;

        svg {
            position: absolute;
            top: 3px;
            right: 0;
        }

    }

</style>

